import React from 'react';
import ServicesPage from '../../components/ServicesPage';
import { TextSection, Declaration, UL, LI, DL, DT, DD } from '../../components/textComponents';

import comLandscapingPhoto from '../../../static/assets/medical_society.jpg';

const keywords = [
  'VLP',
  'Commercial',
  'Landscaping',
  'Landscaper',
  'Lawn Maintenace',
  'Matt Vojticek',
  'Northeast Ohio',
];

const page = {
  title: 'Commercial Landscaping',
  keywords: keywords,
  description: 'VLP Landscaping performs landscaping and lawn maintenance for local businesses and commercial properties.',
};

const heading = {
  title: 'Commercial Landscaping',
  img: comLandscapingPhoto,
  alt: 'Green grass lawn and landscaping for commercial property',
};

export default () => (
  <ServicesPage page={page} heading={heading}>
    <TextSection>
      <Declaration>
        VLP Landscaping maintains and beautifies the grounds of commercial properties:
      </Declaration>
      <UL css={`
        margin-bottom: 5rem;
        column-count: auto;
        column-width: 25rem;
      `}>
        <LI>Condominiums</LI>
        <LI>Manufacturing Facilities</LI>
        <LI>Office Complexes</LI>
        <LI>Storefronts</LI>
        <LI>Retail</LI>
        <LI>Body Shops</LI>
        <LI>Dentist Offices</LI>
        <LI>Parking Lots</LI>
        <LI>Beauty Salons</LI>
        <LI>AND MORE!</LI>
      </UL>
      <Declaration>
        VLP Landscaping offers the following services:
      </Declaration>
      <DL>
        <DT>Mowing</DT>
        <DD>With this landscaping maintenance service, we will mow your grass as needed seasonally. We will also trim and edge any sidewalks and obstacles and blow clippings from hard surfaces.</DD>

        <DT>Bed Edging</DT>
        <DD>We will cut out and edge all the exsisting beds with a nice sharp, deep edge.</DD>

        <DT>Weed Care</DT>
        <DD>As part of our landscaping maintenance services, we will help minimize and control weeds in your landscaping by hand weeding and applying weed control treatments to your flower and plant beds.</DD>

        <DT>Mulching</DT>
        <DD>With our mulching services, we will spread mulch in all of your beds and around your trees as needed for aesthetic appeal and weed control.</DD>

        <DT>Trimming and Pruning</DT>
        <DD>With this service, we will trim and prune any shrubs, plants, and ornamental grasses to ensure good shape and plant health.</DD>

        <DT>Spring Cleanup</DT>
        <DD>We will clean all beds and turf free of any debris and cut back any dead annuals.</DD>

        <DT>Leaf Removal</DT>
        <DD>We will remove fallen leaves from both your landscaping beds and turf areas.</DD>

        <DT>Spring and Fall Annual Planting</DT>
        <DD>We can plant a variety of annual flowers and plants throughout your landscape to provide color and design in any season.</DD>

        <DT>Removal of Annuals</DT>
        <DD>This landscaping maintenance service includes removing any dead annuals after the growing season has ended.</DD>
      </DL>
    </TextSection>
  </ServicesPage>
);
